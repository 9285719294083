import { DesignQuery } from '@generated/GraphqlTypes';
import FloorPlan from '@localTypes/FloorPlanDetailed';
import slug from '@helpers/slug';

const decodeFloorPlans = (data: DesignQuery): FloorPlan[] => {
  return (data.markdownRemark?.frontmatter?.variants || []).map(variant => ({
    name: `${variant?.name}`,
    title: `${variant?.title}`,
    slug: `${slug(variant?.name || '')}`,
    designNameSlug: `${slug(data?.markdownRemark?.frontmatter?.name || '')}`,
    price: variant?.price || 0,
    drawing: variant?.image?.publicURL || '',
    features: new Map(
      Object.keys(variant?.features || {}).map(a => [
        a,
        (variant?.features as any)[a] || 0,
      ])
    ),
    metrics: new Map(
      Object.keys(variant?.metrics || {}).map(a => [
        a,
        (variant?.metrics as any)[a] || 0,
      ])
    ),
  }));
};

export default decodeFloorPlans;
