import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import AllDesigns from '@staticQueries/AllDesigns';
import CardDesign from '@webMolecules/Card/CardDesign';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as Route from '@routes';
import * as styles from './youMayAlsoLike.module.scss';
import { minPrice } from '@helpers/designs';

export interface YouMayAlsoLikeProps {
  exclude?: string;
}

const YouMayAlsoLike: React.FC<YouMayAlsoLikeProps> = ({ exclude }) => (
  <AllDesigns exclude={exclude}>
    {designs => (
      <Box>
        <Container>
          <Box className={styles.youMayAlsoLikeInner}>
            <h2>You may also like</h2>
            <Box className={styles.youMayAlsoLikeColumns}>
              {designs.slice(0, 2).map(design => (
                <CardDesign
                  key={design.title}
                  cta={`See floorplans`}
                  href={Route.designs(design.slug)}
                  price={minPrice(design)}
                  title={design.title}
                  slides={design.images.map((image, i) => ({
                    slide: (
                      <GatsbyImage
                        loading="lazy"
                        image={image}
                        alt={`${design.title} ${i}`}
                      />
                    ),
                  }))}
                />
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    )}
  </AllDesigns>
);

export default YouMayAlsoLike;
