import React from 'react';
import * as styles from './youtubeEmbed.module.scss';

export interface YoutubeEmbedProps {
  videoId: string;
  fluid?: boolean;
  playerRatio?: '1:1' | '16:9';
  className?: string;
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({
  videoId,
  fluid = true,
  playerRatio,
  className,
}) => {
  const classNames = [
    styles.youtubeEmbed,
    className || '',
    fluid ? styles.youtubeEmbedFluid : '',
    playerRatio && playerRatio === '1:1' ? styles.youtubeEmbedRatioSquare : '',
    playerRatio && playerRatio === '16:9' ? styles.youtubeEmbedRatioWide : '',
  ].join(' ');

  return (
    <div className={classNames}>
      <iframe
        title="YouTube"
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&playsinline=1&controls=1&rel=0&loop=1&playlist=${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default YoutubeEmbed;
