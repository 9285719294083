// extracted by mini-css-extract-plugin
export var cardFloorPlanBody = "floorPlans-module--cardFloorPlanBody--sI7Q6";
export var cardFloorPlanFooter = "floorPlans-module--cardFloorPlanFooter--D8fW5";
export var cardFloorPlanFooterArrow = "floorPlans-module--cardFloorPlanFooterArrow--OrJ19";
export var cardFloorPlanFooterFeature = "floorPlans-module--cardFloorPlanFooterFeature--roEkg";
export var cardFloorPlanImage = "floorPlans-module--cardFloorPlanImage--8ShFI";
export var floorPlansFlyerWrapper = "floorPlans-module--floorPlansFlyerWrapper--+-RAb";
export var floorPlansHeading = "floorPlans-module--floorPlansHeading--M7wIs";
export var floorPlansHeadingFilter = "floorPlans-module--floorPlansHeadingFilter--90SXQ";
export var floorPlansHeadingFlyer = "floorPlans-module--floorPlansHeadingFlyer--Y0bre";
export var floorPlansHeadingHeading = "floorPlans-module--floorPlansHeadingHeading--m56Mw";
export var floorPlansInner = "floorPlans-module--floorPlansInner--h9-Ki";
export var floorPlansSlide = "floorPlans-module--floorPlansSlide--22Tfy";
export var floorPlansSlideTitle = "floorPlans-module--floorPlansSlideTitle--sTyP-";
export var floorPlansTableWrapper = "floorPlans-module--floorPlansTableWrapper--EQlni";
export var floorPlansTableWrapperButtonWrapper = "floorPlans-module--floorPlansTableWrapperButtonWrapper--AKJo+";
export var imageZoomer = "floorPlans-module--imageZoomer--i9BGn";
export var imageZoomerActive = "floorPlans-module--imageZoomerActive--ErU9+";
export var imageZoomerFigure = "floorPlans-module--imageZoomerFigure--2KkhL";
export var imageZoomerOriginal = "floorPlans-module--imageZoomerOriginal--dpIFq";
export var imageZoomerZoomed = "floorPlans-module--imageZoomerZoomed--rx-eV";
export var imageZoomerZoomedWrapper = "floorPlans-module--imageZoomerZoomedWrapper--u7mfl";