import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Button from '@webAtoms/Button/Button';
import Carousel from '@webOrganisms/Carousel/CarouselFloorPlans';
import * as styles from './floorPlans.module.scss';
import FloorPlanDetailed from '@localTypes/FloorPlanDetailed';
import Icon from '@webAtoms/Icon/Icon';
import {
  filterFloorPlans,
  FilterArr,
  filterArrFromString,
} from '@helpers/designs';

export interface FloorPlansProps {
  floorPlans: FloorPlanDetailed[];
  flyerPdf: string;
}

const FloorPlans: React.FC<FloorPlansProps> = ({ floorPlans, flyerPdf }) => {
  const makeSlides = (floorPlans: FloorPlanDetailed[]) =>
    floorPlans.map(floorPlan => {
      return {
        slide: <FloorPlanSlide floorPlan={floorPlan} />,
        caption: (
          <Box>
            <FloorPlanTable floorPlan={floorPlan} />
            <Box mobile className={styles.floorPlansFlyerWrapper}>
              <Button
                href={`${flyerPdf}?t=${new Date().getTime()}`}
                intent="primary"
                target="_blank"
                fluid
                bold
                icon="download"
              >
                Download flyer
              </Button>
            </Box>
          </Box>
        ),
      };
    });

  // Get previous set filter
  let filterArr: FilterArr = [];
  try {
    if (typeof window !== 'undefined') {
      filterArr = filterArrFromString(window.location.search);
      // filterArr = JSON.parse(
      //   window.sessionStorage.getItem('features-filter') || '[]'
      // ) as FilterArr;
    }
  } catch (err) {
    console.error(err);
  }

  const [state, setState] = React.useState(filterArr);
  const baseFilteredFloorPlans = filterFloorPlans(floorPlans, []);
  let filteredFloorPlans = filterFloorPlans(floorPlans, filterArr);
  React.useEffect(() => {
    filteredFloorPlans = filterFloorPlans(floorPlans, filterArr);
  }, [state]);

  const onClickClearFilters = () => {
    filterArr = [];
    // if (typeof window !== 'undefined') {
    //   window.sessionStorage.setItem(
    //     'features-filter',
    //     JSON.stringify(filterArr)
    //   );
    // }
    if (typeof window !== 'undefined') {
      window.history.replaceState(null, '', window.location.pathname);
    }
    setState(filterArr);
  };

  if (filteredFloorPlans.length === 0) {
    onClickClearFilters();
    filteredFloorPlans = floorPlans;
  }

  return (
    <Box>
      <Container>
        <Box className={styles.floorPlansInner}>
          <Box className={styles.floorPlansHeading}>
            <Box className={styles.floorPlansHeadingHeading}>
              <h2>Floor Plans</h2>
            </Box>

            <Box className={styles.floorPlansHeadingFilter}>
              {filterArr.length > 0 && (
                <>
                  <span>
                    Showing <strong>{filteredFloorPlans.length}</strong> of{' '}
                    <strong>{baseFilteredFloorPlans.length}</strong> plans
                  </span>
                  <a onClick={onClickClearFilters}>Clear filters</a>
                </>
              )}
            </Box>

            <Box desktop className={styles.floorPlansHeadingFlyer}>
              <Button
                href={flyerPdf}
                intent="primary"
                target="_blank"
                fluid
                icon="download"
              >
                Download flyer
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box>
        <Carousel
          key={filterFloorPlans.length}
          slides={makeSlides(filteredFloorPlans)}
        />
      </Box>
    </Box>
  );
};

interface FloorPlanTableProps {
  floorPlan: FloorPlanDetailed;
}

const FloorPlanTable: React.FC<FloorPlanTableProps> = ({ floorPlan }) => {
  const [range, name = ''] = floorPlan.title.split(' ');

  return (
    <Box className={styles.floorPlansTableWrapper}>
      <h3>
        {range}
        <span>{name}</span>
      </h3>
      <table>
        <tbody>
          <tr>
            <td>Bedrooms</td>
            <td>{floorPlan.features.get('bedrooms') || 0}</td>
          </tr>
          <tr>
            <td>Bathrooms</td>
            <td>{floorPlan.features.get('bathrooms') || 0}</td>
          </tr>
          <tr>
            <td>Modules</td>
            <td>{floorPlan.metrics.get('modules') || 0}</td>
          </tr>
          <tr>
            <td>Living Area</td>
            <td>{floorPlan.metrics.get('area') || 0}m2</td>
          </tr>
          <tr>
            <td>Deck Area</td>
            <td>{floorPlan.metrics.get('deck') || 0}m2</td>
          </tr>
          <tr>
            <td>Length</td>
            <td>{floorPlan.metrics.get('length') || 0}m</td>
          </tr>
          <tr>
            <td>Width</td>
            <td>{floorPlan.metrics.get('width') || 0}m</td>
          </tr>
          <tr>
            <td>Height</td>
            <td>{floorPlan.metrics.get('height') || 0}m</td>
          </tr>
          <tr>
            <td>From</td>
            <td>${(floorPlan.price || 0).toLocaleString('en-AU')} + GST</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

interface FloorPlanSlideProps {
  floorPlan: FloorPlanDetailed;
}

const FloorPlanSlide: React.FC<FloorPlanSlideProps> = ({ floorPlan }) => {
  const [range, name = ''] = floorPlan.title.split(' ');

  return (
    <Box className={styles.floorPlansSlide}>
      <Box mobile>
        <Box className={styles.cardFloorPlanImage}>
          <img src={floorPlan.drawing} alt={floorPlan.title} />
        </Box>
        <Box className={styles.cardFloorPlanBody}>
          <h3>
            {range}
            <span>{name}</span>
          </h3>
          <p>From ${floorPlan.price.toLocaleString('en-AU')} + GST</p>
        </Box>
        <Box className={styles.cardFloorPlanFooter}>
          <Box className={styles.cardFloorPlanFooterFeature}>
            <Icon name="bedrooms" />
            <span>{floorPlan.features.get('bedrooms')}</span>
          </Box>
          <Box className={styles.cardFloorPlanFooterFeature}>
            <Icon name="bathrooms" />
            <span>{floorPlan.features.get('bathrooms')}</span>
          </Box>
          <Box className={styles.cardFloorPlanFooterFeature}>
            <Icon name="livingSpaces" />
            <span>{floorPlan.features.get('livingSpaces')}</span>
          </Box>
          {floorPlan.features.get('workSpaces') ? (
            <Box className={styles.cardFloorPlanFooterFeature}>
              <Icon name="workSpaces" />
              <span>{floorPlan.features.get('workSpaces')}</span>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box desktop>
        <Box className={styles.floorPlansSlideTitle}>{floorPlan.name}</Box>
        {/* <InnerImageZoom
          src={floorPlan.drawing}
          zoomScale={isSafari ? 1.4 : 11}
        /> */}
        <ImageZoomer src={floorPlan.drawing} />
      </Box>
    </Box>
  );
};

interface ImageZoomerProps {
  src: string;
}

interface ImageZoomerState {
  isZoomed: boolean;
  x: number;
  y: number;
}

export const ImageZoomer: React.FC<ImageZoomerProps> = ({ src }) => {
  const originalRef = React.useRef<HTMLImageElement>(null);
  const [state, setState] = React.useState<ImageZoomerState>({
    isZoomed: false,
    x: 0,
    y: 0,
  });

  const classNames = [
    styles.imageZoomer,
    state.isZoomed ? styles.imageZoomerActive : '',
  ].join(' ');

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = originalRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    const x = 0 - (100 / rect.width) * (event.clientX - rect.x);
    const y = 0 - (100 / rect.height) * (event.clientY - rect.y);
    setState({ ...state, x, y });
  };

  return (
    <div
      className={classNames}
      onClick={() => setState({ ...state, isZoomed: !state.isZoomed })}
      onMouseMove={onMouseMove}
      onMouseLeave={() => setState({ ...state, isZoomed: false })}
      // onMouseEnter={() => setState({ ...state, isZoomed: true })}
    >
      <figure className={styles.imageZoomerFigure}>
        <img
          src={src}
          className={styles.imageZoomerOriginal}
          ref={originalRef}
        />
        <div
          className={styles.imageZoomerZoomedWrapper}
          style={{
            transform: `translate3d(${state.x}%,${state.y}%,0)`,
          }}
        >
          <img src={src} className={styles.imageZoomerZoomed} />
        </div>
      </figure>
    </div>
  );
};

export default FloorPlans;
