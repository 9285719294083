import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Button from '@webAtoms/Button/Button';
import CarouselPagination from '@webOrganisms/Carousel/CarouselPagination/CarouselPagination';
import SwiperClass from 'swiper/types/swiper-class';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { Controller, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import * as styles from './carouselFloorPlan.module.scss';

export interface CarouselProps {
  slides: CarouselSlide[];
}

export interface CarouselSlide {
  slide: React.ReactElement;
  caption: React.ReactElement;
}

/**
 * https://swiperjs.com/react
 */
const Carousel: React.FC<CarouselProps> = ({ slides }) => {
  const [imageSwiper, setImageSwiper] = React.useState<SwiperClass | null>(
    null
  );
  const [tableSwiper, setTableSwiper] = React.useState<SwiperClass | null>(
    null
  );

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 1024;

  const sharedSwiperSettings: SwiperOptions = {
    speed: 600,
    loop: isMobile ? false : true,
    slidesPerView: isMobile ? 1 : 'auto',
    centeredSlides: false,
  };

  const imageSwiperSettings: SwiperOptions = {
    ...sharedSwiperSettings,
    modules: [Controller],
    pagination: {
      type: 'progressbar',
    },
    noSwiping: false,
    shortSwipes: true,
    simulateTouch: true,
  };

  const tableSwiperSettings: SwiperOptions = {
    ...sharedSwiperSettings,
    modules: [Controller, EffectFade],
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    noSwiping: true,
    shortSwipes: false,
    simulateTouch: false,
  };

  const classNames = [styles.carousel].join(' ');
  const onClickNextSlide = () => {
    imageSwiper && imageSwiper.slideNext();
  };

  return (
    <Box className={classNames}>
      <Container>
        <Box className={styles.carouselInner}>
          <Box className={styles.carouselInnerSlides}>
            {slides.length === 1 ? (
              <Box className={styles.carouselInnerNoSlider}>
                <Box>{slides[0].slide}</Box>
              </Box>
            ) : (
              <Swiper
                {...imageSwiperSettings}
                controller={{
                  control: tableSwiper ? tableSwiper : undefined,
                }}
                onSwiper={setImageSwiper}
                onActiveIndexChange={(newSwiper: SwiperClass) => {
                  setCurrentIndex(newSwiper.realIndex);
                }}
              >
                {slides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    {a => {
                      const onClick = (event: React.MouseEvent) => {
                        // if (a.isPrev) {
                        //   event.preventDefault();
                        //   imageSwiper?.slidePrev();
                        // }
                        if (a.isNext) {
                          event.preventDefault();
                          imageSwiper?.slideNext();
                        }
                      };
                      return <div onClick={onClick}>{slide.slide}</div>;
                    }}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Box>

          {slides.length > 1 && (
            <>
              <Box mobile className={styles.carouselProgressbarMobile}>
                <Box
                  className={styles.carouselProgressbarFill}
                  style={{
                    width: `${(100 / slides.length) * (currentIndex + 1)}%`,
                  }}
                />
              </Box>

              {imageSwiper && (
                <Box mobile className={styles.carouselPaginationWrapper}>
                  <CarouselPagination
                    amounfOfSlides={slides.length}
                    currentIndex={currentIndex}
                    swiper={imageSwiper}
                  />
                </Box>
              )}

              <Box mobile className={styles.carouselInnerNextSlideWrapper}>
                <Button
                  intent="neutral"
                  bold
                  icon="arrowRightAlt"
                  onClick={onClickNextSlide}
                >
                  Next plan
                </Button>
              </Box>
            </>
          )}

          <Box className={styles.carouselInnerCaptions}>
            <Box>
              {slides.length === 1 ? (
                <Box>{slides[0].caption}</Box>
              ) : (
                <Swiper
                  {...tableSwiperSettings}
                  controller={{
                    control: imageSwiper ? imageSwiper : undefined,
                  }}
                  onSwiper={setTableSwiper}
                >
                  {slides.map((slide, index) => (
                    <SwiperSlide key={index}>{slide.caption}</SwiperSlide>
                  ))}
                </Swiper>
              )}
            </Box>
          </Box>
        </Box>

        {slides.length > 1 && (
          <>
            <Box desktop className={styles.carouselInnerNextSlideWrapper}>
              <Button
                intent="neutral"
                bold
                icon="arrowRightAlt"
                onClick={onClickNextSlide}
              >
                Next plan
              </Button>
            </Box>

            <Box desktop className={styles.carouselProgressbar}>
              <Box
                className={styles.carouselProgressbarFill}
                style={{
                  width: `${(100 / slides.length) * (currentIndex + 1)}%`,
                }}
              />
            </Box>

            {imageSwiper && (
              <Box desktop className={styles.carouselPaginationWrapper}>
                <CarouselPagination
                  amounfOfSlides={slides.length}
                  currentIndex={currentIndex}
                  swiper={imageSwiper}
                />
              </Box>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default Carousel;
