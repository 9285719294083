import React from 'react';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as Route from '@routes';
import Carousel from '@webOrganisms/Carousel/Carousel';
import * as styles from './hero.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import Design from '@localTypes/Design';
import YoutubeEmbed from '@webAtoms/YoutubeEmbed/YoutubeEmbed';

export interface HeroProps {
  design: Design;
}

const Hero: React.FC<HeroProps> = ({ design }) => {
  const title = design.title;

  const [slides, setSlides] = React.useState([
    ...design.images.map(image => ({
      slide: <GatsbyImage alt={title} image={image} loading="eager" />,
    })),
  ]);

  React.useEffect(() => {
    if (window.innerWidth < 1024 && design.video) {
      const youtubeEmbedUrl = `https://www.youtube.com/embed/${design.video}?modestbranding=1&playsinline=1&controls=0&rel=0&loop=1&playlist=${design.video}`;
      setSlides([
        {
          slide: (
            <div className={styles.heroVideo}>
              <iframe src={youtubeEmbedUrl} allowFullScreen />
            </div>
          ),
        },
        ...slides,
      ]);
    }
  }, []);

  return (
    <Box>
      <Box className={styles.heroTop}>
        <Container padding={Spacing.S}>
          <Box className={styles.heroBreadcrumbs}>
            <a href={Route.designs()}>Modular homes</a>
            <span>&gt;</span>
            <span>{title}</span>
          </Box>
          <Box className={styles.heroHeading}>
            <h1>{title}</h1>
          </Box>
        </Container>
      </Box>
      <Carousel type="hero" slides={slides} />
    </Box>
  );
};

export default Hero;
