import React from 'react';
import * as styles from './readMoreInline.module.scss';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Circle from '@webAtoms/Circle/Circle';
import Icon from '@webAtoms/Icon/Icon';

export interface ReadMoreInlineProps {
  isActive?: boolean;
  intro: React.ReactNode;
  children?: React.ReactNode;
}

const ReadMoreInline: React.FC<ReadMoreInlineProps> = ({
  isActive = false,
  intro,
  children,
}) => {
  const [active, setActive] = React.useState(isActive);
  React.useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <Box
      semantic="article"
      className={`${active ? styles.readMoreInlineActive : ''}`}
    >
      {intro}

      <Box className={styles.readMoreInlineContent}>{children}</Box>

      <Box semantic="span" paddingTop={Spacing.S}>
        <button
          onClick={() => setActive(!active)}
          className={styles.readMoreInlineButton}
        >
          <Circle rotate={active === true ? 180 : 0}>
            <Icon name="arrowDown" />
          </Circle>
          <span className={styles.readMoreInlineButtonLabel}>
            {' '}
            {active ? 'Less' : 'More'}
          </span>
        </button>
      </Box>
    </Box>
  );
};

export default ReadMoreInline;
