import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Button from '@webAtoms/Button/Button';
import Container from '@webAtoms/Container/Container';
import Design from '@localTypes/Design';
import YoutubeEmbed from '@webAtoms/YoutubeEmbed/YoutubeEmbed';
import ReadMoreInline from '@webOrganisms/ReadMoreInline/ReadMoreInline';
import * as Route from '@routes';
import * as styles from './intro.module.scss';

export interface IntroProps {
  design: Design;
}

const Intro: React.FC<IntroProps> = ({ design }) => {
  const bodyA = design.body.slice(0, design.body.indexOf('</p>') + 4);
  const bodyB = design.body.slice(design.body.indexOf('</p>') + 4);

  const fallBackImage = design.images[1] ?? design.images[0];

  const [media, setMedia] = React.useState<any>({
    type: design.video ? 'video' : 'image',
    source: design.video || fallBackImage,
  });

  React.useEffect(() => {
    if (window.innerWidth < 1024 && design.video) {
      setMedia({
        type: 'image',
        source: fallBackImage,
      });
    }
  }, []);

  return (
    <Box className={styles.intro}>
      <Container>
        <Box className={styles.introInner}>
          <Box className={styles.introColumnContent}>
            <Box className={styles.introColumnContentInner}>
              <Box desktop>
                <Box htmlContent={design.body} />

                <Box className={styles.introColumnContentInnerButtonWrapper}>
                  <Button
                    intent="primary"
                    icon="arrowRight"
                    href={Route.contactUs()}
                  >
                    Enquire
                  </Button>
                </Box>
              </Box>
              <Box mobile paddingBottom={Spacing.Xl}>
                <ReadMoreInline intro={<Box htmlContent={bodyA} />}>
                  {<Box htmlContent={bodyB} />}
                </ReadMoreInline>
              </Box>
            </Box>
          </Box>
          <Box className={styles.introColumnVideo}>
            {media.type === 'video' ? (
              <YoutubeEmbed
                className={styles.introColumnVideoWrapper}
                videoId={media.source}
                fluid
                playerRatio={'16:9'}
              />
            ) : (
              <GatsbyImage
                loading="eager"
                className={styles.introColumnVideoWrapper}
                image={media.source}
                alt={design.title}
              />
            )}
          </Box>
          <Box mobile paddingTop={Spacing.Xl2} paddingBottom={Spacing.S}>
            <Button fluid center intent="primary" href={Route.contactUs()}>
              Enquire
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Intro;
